
// Red
$Accent_Red: #FF2D2D
$RedAccent: #FB0808
$RedMain: #DC0000
$RedAccent_10_percent: #FB080810
$RedAccent_50_percent: #FB080850

// Dark
$black: #2A3042
$primary_dark: #2A3042
$Dark_Color: #263238
$Dark_admin: #2A3042
$Dark_admin_99_percent: #2A304299
$Dark_Gray: #676C79

//Grey
$BG_Gray: #F4F4F9
$RegularGray: #676C79
$Grey_light: #D4D4D8
$Grey_admin: #ececfa

// Colors
$Purple: #695CB8
$Green_2: #54C86E
$Green: #7CC726

// Shadow
$ShadowHover: 0 5px 27px #00000025, 0 2px 6px #00000025
$Shadow: 0 1px 2px rgba(0, 0, 0, 0.1), 0 1.85692px 6.48173px rgba(0, 0, 0, 0.0462464)

$BlackBackground: #231F20
$GrayDark: #443E3F
$GreyDark_2: #343031
$GreyDark_3: #282425
$GreyLight: #D4D4D8
$GreyLight_2: #29252680
$GreyLight_very: #E8E5E5
$GrayAccent: #383435

$GilroyFont: 'Gilroy', 'Roboto', sans-serif
$GilroyThin: 100
$GilroyLight: 300
$GilroyRegular: 400
$GilroyMedium: 500
$GilroyBold: 700
$GilroyBlack: 900

//sizes
$break_lg: 1366px
$break_md: 1180px
$break_sm: 768px
$break_xs: 500px

// Mixins

@mixin H1_Header($color)
  font-family: 'Gilroy'
  font-style: normal
  font-weight: 900
  font-size: 48px
  line-height: 60px
  color: $color

@mixin H2_Header($color)
  font-family: 'Gilroy'
  font-style: normal
  font-weight: 900
  font-size: 30px
  line-height: 37px
  color: $color

@mixin H3_Header($color)
  font-family: 'Gilroy'
  font-style: normal
  font-weight: 900
  font-size: 24px
  line-height: 30px
  color: $color

@mixin H4_Header($color)
  font-family: 'Gilroy'
  font-style: normal
  font-weight: 900
  font-size: 18px
  line-height: 22px
  color: $color

@mixin paragraph($color)
  font-family: 'Gilroy'
  font-style: normal
  font-weight: 500
  font-size: 16px
  line-height: 25px
  color: $color

@mixin caption($color)
  font-family: Gilroy
  font-size: 12px
  font-style: normal
  font-weight: 400
  line-height: normal
  letter-spacing: 0.301px
  color: $color

@mixin buttons($color)
  font-family: 'Gilroy'
  font-style: normal
  font-weight: 900
  font-size: 16px
  line-height: 20px
  color: $color

@mixin H2_Admin($color)
  font-family: 'Gilroy'
  font-style: normal
  font-weight: 500
  font-size: 20px
  line-height: 24px
  color: $color

@mixin Body2($color)
  font-family: 'Gilroy'
  font-style: normal
  font-weight: 400
  font-size: 15px
  line-height: 23px
  letter-spacing: 0.282191px
  color: $color

/* Body_admin */
@mixin Body_admin($color)
  font-family: Gilroy
  font-size: 18px
  font-style: normal
  font-weight: 400
  line-height: 22.575px
  letter-spacing: 0.282px
  color: $color
