@import "@/Assets/sass/_variables";
@import "@/Assets/sass/mixins";

.messageActive {
  border: 1px solid #8A8AE4FF !important;
}

.delete {
  margin: 17px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.message {
  border-radius: 8px;
  background: $Grey_admin;
  border: 1px solid $Grey_admin;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: #2A3042;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 100%;
  gap: 8px;
  margin-bottom: 12px;

  .row {
    width: 100%;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
  }

  .text {
    padding: 17px 11px 17px 17px;
    width: 100%;
    overflow: hidden;
    overflow-wrap: break-word;
  }

  .date {
    display: block;
    font-size: 10px;
    line-height: 1;
    @include caption($RegularGray)
  }
}